import { createTheme } from "@mui/material";

declare module "@mui/material/Chip" {
    interface ChipPropsColorOverrides {
        langTab: true;
    }
}
declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        secondaryGrey: true;
        search: true;
    }
}

declare module "@mui/material/styles" {
    interface Palette {
        search: Palette["primary"];
        langTab: {
            activeBg: string;
            activeText: string;
            inactiveBg: string;
            inactiveText: string;
        };
    }
    interface PaletteOptions {
        search?: PaletteOptions["primary"];
        langTab?: {
            activeBg: string;
            activeText: string;
            inactiveBg: string;
            inactiveText: string;
        };
    }
}
const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    fontSize: "62.5%",
                    fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`,
                },
                body: {
                    margin: 0,
                    padding: 0,
                    fontSize: "1.6rem",
                },
            },
        },
    },
    palette: {
        mode: "light",
        primary: {
            main: "#5D2EFB",
            light: "#9385DD",
        },
        secondary: {
            main: "#7C5264",
        },
        error: {
            main: "#BA1A1A",
        },
        warning: {
            main: "#e4404b",
        },
        info: {
            main: "#C9BEFF",
            dark: "#9385DD",
            light: "#D8D0FF",
        },
        search: {
            main: "#148690",
            dark: "#04545b",
            light: "#89b4a6",
        },
        background: {
            default: "#FDF8FD",
            paper: "#fff",
        },
        langTab: {
            activeBg: "#C9BEFF",
            activeText: "#2F009B",
            inactiveBg: "#F4EEFF",
            inactiveText: "#5F52A6",
        },
    },

    typography: {
        fontSize: 14,
        htmlFontSize: 10,
    },
});

export default theme;
